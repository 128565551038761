// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h1 { display: inline; }
.p { display: inline; }`, "",{"version":3,"sources":["webpack://./src/components/Cart.css"],"names":[],"mappings":"AAAA,MAAM,eAAe,EAAE;AACvB,KAAK,eAAe,EAAE","sourcesContent":[".h1 { display: inline; }\n.p { display: inline; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
